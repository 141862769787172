//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";

.card-toolbar {
  @media screen and (max-width: 998px) {
    //do Smth
    align-items: end;
    // width: 100%;
  }
}

.upload-image {
  height: 180px;
  border: 1px dashed #00a3ff !important;
  cursor: pointer;
  background: #f1faff;

  div {
    color: #00a3ff !important;
  }
}

.no-pad {
  padding: 0px !important;
}

.signatureCanvas {
  width: 100%;
  min-height: 300px;
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 5px;
}
