.box-container {
    display: flex;
    margin-bottom: 1.2em;

    .box {
        padding: 1em;
        font-size: 2em;
        width: 100%;
        border: 1px dashed var(--kt-text-muted);
        ;
        border-radius: 10px;
        text-align: center;

        .primary {
            color: $primary;
        }

        .warning {
            color: $warning;
        }

        .success {
            color: $success;
        }

        .main-title {
            font-size: 0.5em;
            font-weight: 700;
        }

        .sub-title {
            font-size: 1em;
        }
    }

    @media (min-width: 900px) {
        margin: 0 20%;
    }
}